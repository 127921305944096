import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import axios from "axios";
import MaskHelper from "../../Helpers/mask";
import { STATE_NAMES } from "../../services/constants";
import NewApi from "../../services/new-api";
import { useTranslation } from 'react-i18next';
import sendToLogger from '../../Helpers/errorLogger';

const newApi = new NewApi();

export const BillingAddressView = ({
  watch,
  errors,
  register,
  translate,
  onSetValue,
  clearErrors,
  onFetchingZipCode,
  onZipCodeFetched,
  fetchingZipCode,
  zipCodeFetched,
}) => {
  const { t } = useTranslation();
  const [userAddress, setUserAddress] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [addressForm, setAddressForm] = useState({
    zip_code: "",
    street: "",
    neighborhood: "",
    number: "",
    complement: "",
    state: "",
    city: "",
  });

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        const { data } = await newApi.get("/users/me");
        if (data?.address) {
          setUserAddress(data.address);
          setAddressForm(data.address);
          Object.entries(data.address).forEach(([key, val]) =>
            onSetValue(`payment.${key}`, val)
          );
        }
      } catch (error) {
        console.error("Failed to fetch user address", error);
      }
    };

    fetchUserAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const zipCode = MaskHelper.numberMask(watch("payment.zip_code"));

    if (zipCode?.length >= 8) {
      onFetchingZipCode(true);

      axios
        .get(`https://viacep.com.br/ws/${zipCode}/json/`, { timeout: 5000 })
        .then(({ data }) => {
          if (!data.error) {
            onSetValue("payment.zip_code", MaskHelper.zipcodeMask(zipCode));
            onSetValue("payment.street", data.logradouro || "");
            onSetValue("payment.neighborhood", data.bairro || "");
            onSetValue("payment.city", data.localidade || "");
            onSetValue("payment.state", data.uf || "");
            clearErrors();
          }
        })
        .catch((error) => sendToLogger(error))
        .finally(() => {
          onZipCodeFetched(true);
          onFetchingZipCode(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("payment.zip_code")]);

  return (
    <div>
      {userAddress && !isEditing ? (
        <div className="prefillContainer">
          <div className="prefillInfoContainer">
            <p className="mb-0">{`${t("Checkout.zipcode")}: ${addressForm.zip_code}`}</p>
            <p className="mb-0">{`${addressForm.street}, ${addressForm.number}`}</p>
            <p className="mb-0">{`${addressForm.neighborhood} - ${addressForm.city} - ${addressForm.state}`}</p>
            {addressForm.complement && (
              <p className="mb-0">
                {`${t("ProfileForm.complement")}: ${addressForm.complement}`}
              </p>
            )}
          </div>
          <button
            className="prefillEditButton font-weight-bold"
            onClick={() => setIsEditing(true)}
          >
            {t("Checkout.edit")}
          </button>
        </div>
      ) : (
        <Form>
          <Row>
            <Col xs={7} md={4} className="mb-2">
              <Form.Group>
                <Form.Label htmlFor="zip_code">
                  <span className="text-danger">*</span>
                  {translate("Checkout.zipcode")}
                </Form.Label>
                <Form.Control
                  id="zip_code"
                  type="text"
                  className={errors.payment?.zip_code && "is-invalid"}
                  {...register("payment.zip_code")}
                />
                {errors.payment?.zip_code && (
                  <Form.Text className="error-message">
                    {errors.payment?.zip_code?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            {fetchingZipCode ? (
              <Col className="d-flex align-items-end">
                <span className="pb-4">
                  {translate("Checkout.searchingZipcode")}
                </span>
              </Col>
            ) : (
              <Col xs={5} className="postal-code mb-2">
                <a
                  rel="noopener noreferrer"
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  target="_blank"
                  onClick={() => onZipCodeFetched(true)}
                >
                  {translate("Checkout.unknownZipcode")}
                </a>
              </Col>
            )}
          </Row>

          <Row className={zipCodeFetched ? "d-flex" : "d-none"}>
            <Col md={7} className="mb-2">
              <Form.Group>
                <Form.Label htmlFor="address">
                  <span className="text-danger">*</span>
                  {translate("Checkout.address")}
                </Form.Label>
                <Form.Control
                  id="street"
                  type="text"
                  className={errors.payment?.street && "is-invalid"}
                  {...register("payment.street")}
                />
                {errors.payment?.street && (
                  <Form.Text className="error-message">
                    {errors.payment?.street?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={5} className="mb-2">
              <Form.Group>
                <Form.Label htmlFor="number">
                  <span className="text-danger">*</span>
                  {translate("Checkout.number")}
                </Form.Label>
                <Form.Control
                  id="number"
                  type="text"
                  className={errors.payment?.number && "is-invalid"}
                  {...register("payment.number")}
                />
                {errors.payment?.number && (
                  <Form.Text className="error-message">
                    {errors.payment?.number?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-2">
              <Form.Group>
                <Form.Label htmlFor="neighborhood">
                  <span className="text-danger">*</span>
                  {translate("Checkout.neighborhood")}
                </Form.Label>
                <Form.Control
                  id="neighborhood"
                  type="text"
                  className={errors.payment?.neighborhood && "is-invalid"}
                  {...register("payment.neighborhood")}
                />
                {errors.payment?.neighborhood && (
                  <Form.Text className="error-message">
                    {errors.payment?.neighborhood?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-2">
              <Form.Group>
                <Form.Label htmlFor="city">
                  <span className="text-danger">*</span>
                  {translate("Checkout.city")}
                </Form.Label>
                <Form.Control
                  id="city"
                  name="city"
                  type="text"
                  className={errors.payment?.city && "is-invalid"}
                  {...register("payment.city")}
                />
                {errors.payment?.city && (
                  <Form.Text className="error-message">
                    {errors.payment?.city?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={4} className="mb-2">
              <Form.Group htmlFor="state">
                <Form.Label htmlFor="state">
                  <span className="text-danger">*</span>
                  {translate("Checkout.state")}
                </Form.Label>
                <Form.Control
                  id="state"
                  as="select"
                  className={errors.payment?.state && "is-invalid"}
                  {...register("payment.state")}
                >
                  <option value="">{translate("Checkout.selectState")}</option>
                  {Object.keys(STATE_NAMES).map((abbr, idx) => (
                    <option id={abbr} key={idx} value={abbr}>
                      {STATE_NAMES[abbr]}
                    </option>
                  ))}
                </Form.Control>
                {errors.payment?.state && (
                  <Form.Text className="error-message">
                    {errors.payment?.state?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};
